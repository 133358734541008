.MusicStore {
  background-image: url("../../assets/backgrounds/gs.png");
  background: linear-gradient(rgba(196, 102, 0, 0.2), rgba(155, 89, 182, 0.2)),
    url("../../assets/backgrounds/gs.png");
  border-radius: 0.25rem;

  & .topMenu {
    background-color: #000;
    color: #f5f5f5;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
  }

  & .accordian {
    width: 805px;
    height: 360px;
    overflow: hidden;
    margin-top: 1.5rem !important;
    margin: auto;

    @media screen and (max-width: 786px) {
      display: none;
    }

    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.35);
    -webkit-box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.35);
    -moz-box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.35);

    & ul {
      width: 2000px;
      margin: 0;
      padding: 0;
      &:hover li {
        width: 40px;
      }

      & li {
        position: relative;
        display: block;
        width: 160px;
        float: left;

        border-left: 1px solid #888;

        box-shadow: 0 0 25px 10px rgba(0, 0, 0, 0.5);
        -webkit-box-shadow: 0 0 25px 10px rgba(0, 0, 0, 0.5);
        -moz-box-shadow: 0 0 25px 10px rgba(0, 0, 0, 0.5);

        /*Transitions to give animation effect*/
        transition: all 0.5s;
        -webkit-transition: all 0.5s;
        -moz-transition: all 0.5s;
        /*If you hover on the images now you should be able to 
            see the basic accordian*/

        & img {
          display: block;
        }

        & .imageTitle {
          background: rgba(0, 0, 0, 0.5);
          position: absolute;
          left: 0;
          bottom: 0;
          width: 640px;

          & span {
            display: block;
            color: #fff;
            text-decoration: none;
            padding: 20px;
            font-size: 16px;
          }
        }

        &:hover {
          width: 640px;
        }
      }
    }
  }

  & .albumArts {
    padding: 10px 15px;
    margin-top: 1rem !important;
    border-top: 1px solid rgba(255, 255, 255, 0.4);

    @media screen and (max-width: 425px) {
      padding: 0;
    }

    & .album {
      width: 160px;
      height: 160px;
      border-radius: 0.25rem;
      box-shadow: 0 3px 8px rgba(0, 0, 0, 0.4);
      cursor: pointer;
      position: relative;

      &:hover {
        & .title {
          display: block;
        }
      }

      & .title {
        position: absolute;
        padding: 0.5rem;
        width: 100%;
        box-sizing: border-box;
        background-color: rgba(0, 0, 0, 0.6);
        color: #f5f5f5;
        bottom: 0;
        display: none;
        transition: all 0.5s ease-in-out;
      }

      & img {
        width: 100%;
        border-radius: 0.25rem;
      }
    }
  }
}
