.Settings {
  text-align: center;
  padding: 20px 30px;

  & .settingTile {
    width: 120px;
    cursor: pointer;

    & .iconContainer {
      height: 120px;
      border: 1px solid;
      position: relative;
      border-radius: 0.25rem;
      transition: all 0.2s ease-in-out;

      &:hover {
        border-color: transparent;
        box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3),
          0 15px 12px rgba(0, 0, 0, 0.22) !important;
      }
    }

    & .tileIcon {
      font-size: 90px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
