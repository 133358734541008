.ProfileCard {
  border: 2px solid;
  width: 280px;
  padding: 20px 30px;
  border-radius: 0.5rem;
  position: relative;
  margin: auto;
  transition: all 0.2s ease-in-out;

  &:hover {
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22) !important;

    & .gearOption {
      display: block;
    }
  }

  & .gearOption {
    display: none;
    position: absolute;
    top: 5px;
    right: 5px;
  }

  & .profileIcon {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin: auto;
    text-align: center;
    position: relative;

    & .initials {
      color: #1b1c1e;
      font-size: 50px; /* 50% of parent */
      line-height: 1;
      position: relative;
      top: 25px; /* 25% of parent */
    }

    &::before {
      content: "";
      border: 3px solid #1b1c1e;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      border-radius: 50%;
    }
  }

  & .profileIntro {
    padding: 10px 15px;
    text-align: justify;
  }

  & .profileActions {
    padding: 5px 10px;
  }
}
