.padding2030 {
  padding: 20px 30px;
}

.galleryWrapper {
  box-sizing: border-box;
  max-width: 1440px;
  margin: auto;

  @media (max-width: 768px) {
    padding: 0;
  }

  & img {
    height: 100%;
    width: 100%;
    display: block;
    object-fit: cover;
    border-radius: 0.25rem;
  }

  & .grid {
    max-width: var(--wrapper);
    display: grid;
    grid-template-columns: repeat(var(--noOfColumns), minmax(0, 1fr));
    grid-auto-flow: dense;
    grid-auto-rows: minmax(
      var(--rh),
      auto
    ); // If the content is taller then the box will grow to fit. This is only going to work if the column value is 1fr
    grid-gap: var(--gutter);
    margin: var(--gutter);

    @media (min-width: 60em) {
      margin: var(--gutter) auto;
    }

    & .gridItem {
      border-radius: 0.25rem;
      cursor: pointer;
      border: 1px solid;

      &:hover {
        box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3),
          0 15px 12px rgba(0, 0, 0, 0.22) !important;
      }

      transition: all 0.2s ease-in-out;
      padding: 0.25rem;
    }
    & .gridItemLG {
      grid-column: span 4;
      grid-row: span 4;

      position: relative;

      @media (max-width: 768px) {
        .caption {
          display: none;
        }
      }

      @media (max-width: 425px) {
        grid-column: span 1;
        grid-row: span 1;

        .caption {
          display: none;
        }
      }
    }

    & .gridItemSM {
      grid-column: span 2;
      grid-row: span 2;
    }

    & .gridItemXS {
      grid-column: span 1;
      grid-row: span 1;
    }
  }
}
