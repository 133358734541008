@import "../../../mixins";

.profileCardWrapper {
  position: relative;
  width: 280px;

  .profileCard {
    padding: 10px 15px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    border-radius: 0.25rem;
    background-color: #f2f2f2;

    & .gearOption {
      display: none;
    }

    &:hover {
      box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3),
        0 15px 12px rgba(0, 0, 0, 0.22) !important;

      position: absolute;
      left: -10px;
      right: -10px;
      z-index: 1;

      & .gearOption {
        display: block;
      }
    }

    & .profileIcon {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      border: 1px solid;
      @include vertical-align;
      text-align: center;

      & .initials {
        @include initials(60px);
        text-transform: uppercase;
      }
    }
  }
}
