.InvoiceManagerDashboard {
  .invoicesTable {
    width: 100%;

    & th,
    td {
      padding: 5px 10px;
      text-align: left;

      &.actions {
        text-align: center;

        & .actionIcon {
          font-size: 20px;
          display: none;
        }
      }

      & .initials {
        color: #1b1c1e;
        font-size: 20px; /* 50% of parent */
        line-height: 1;
        position: relative;
        top: 10px; /* 25% of parent */
      }
    }

    & tr {
      cursor: pointer;
      transition: all 0.2s ease;

      &:hover {
        background-color: #f7f7f7;
        box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3),
          0 15px 12px rgba(0, 0, 0, 0.22) !important;
        & .actionIcon {
          display: inline;
        }
      }
    }
  }
}
