@import "../../_mixins.scss";

.loginCard {
  width: 350px;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  padding: 20px 30px;

  & header {
    text-align: center;
  }

  & .savedUser {
    padding: 0.5rem;
    border-radius: 0.25rem;
    background-color: #f5f5f5;
    cursor: pointer;
    transition: all 02s ease-in-out;

    &:not(:last-of-type) {
      margin-bottom: 1rem;
    }

    & .trashIcon {
      display: none;

      &:hover {
        color: red;
      }
    }

    & .profileIcon {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border: 1px solid red;
      text-align: center;
    }

    & .initials {
      @include initials(40px);
    }

    &:hover {
      & .trashIcon {
        display: block;
      }
    }
  }
}

.verticalCenter {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
