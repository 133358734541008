.panelOverlay {
  position: absolute;
  top: -10px;
  bottom: -10px;
  left: -10px;
  right: -10px;
  border-radius: 0.25rem;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
  animation: slideInRight 0.5s forwards;
}

.newProfilePanel {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 480px;
  background-color: #f5f5f5;
  border-radius: 0.25rem;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22) !important;

  animation: slideInRight 0.5s forwards;

  & .header {
    text-align: center;
    position: relative;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.4);
    padding: 0.5rem;

    & .panelClose {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      padding: 0.25rem;
    }
  }

  & .description {
    padding: 1rem;
    padding-bottom: 0;
    & hr {
      margin-bottom: 0;
    }
  }

  & footer {
    background-color: #f5f5f5;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 1rem;
    z-index: 1;
    text-align: right;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.4);
    border-radius: 0.25rem;
    height: 36px;
  }
}

@keyframes slideInRight {
  0% {
    -webkit-transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(0%);
  }
}
