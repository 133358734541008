.LoadingIndicator {
  position: absolute;
  right: 40px;
  & .spinnerContainer {
    width: 30px;
    height: 30px;
    background-color: #f5f5f5;

    & .outerSpinner {
      background-color: rgba(0, 0, 0, 0);
      border: 5px solid #2196f3;
      opacity: 0.9;
      border-right: 5px solid rgba(0, 0, 0, 0);
      border-left: 5px solid rgba(0, 0, 0, 0);
      border-radius: 35px;
      box-shadow: 0 0 35px #2196f3;
      width: 35px;
      height: 35px;
      margin: 0 auto;
      -webkit-animation: spinPulse 1s infinite linear;
      animation: spinPulse 1s infinite linear;
    }

    & .innerSpinner {
      background-color: rgba(0, 0, 0, 0);
      border: 5px solid #2196f3;
      opacity: 0.9;
      border-left: 5px solid rgba(0, 0, 0, 0);
      border-right: 5px solid rgba(0, 0, 0, 0);
      border-radius: 20px;
      box-shadow: 0 0 15px #2196f3;
      width: 20px;
      height: 20px;
      margin: 0 auto;
      position: relative;
      top: -37px;
      left: 7px;
      -webkit-animation: spinoffPulse 1s infinite linear;
      animation: spinoffPulse 1s infinite linear;
    }
  }

  @-webkit-keyframes spinPulse {
    0% {
      -webkit-transform: rotate(160deg);
      transform: rotate(160deg);
      opacity: 0;
      box-shadow: 0 0 1px #2187e7;
    }

    50% {
      -webkit-transform: rotate(145deg);
      transform: rotate(145deg);
      opacity: 1;
    }

    100% {
      -webkit-transform: rotate(-320deg);
      transform: rotate(-320deg);
      opacity: 0;
    }
  }

  @keyframes spinPulse {
    0% {
      -webkit-transform: rotate(160deg);
      transform: rotate(160deg);
      opacity: 0;
      box-shadow: 0 0 1px #2187e7;
    }

    50% {
      -webkit-transform: rotate(145deg);
      transform: rotate(145deg);
      opacity: 1;
    }

    100% {
      -webkit-transform: rotate(-320deg);
      transform: rotate(-320deg);
      opacity: 0;
    }
  }

  @-webkit-keyframes spinoffPulse {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes spinoffPulse {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
}
