.ProfileView {
  & header.profileCover {
    height: 280px;
    border-radius: 0.25rem;

    background: #ffa17f; /* fallback for old browsers */
    background: -webkit-linear-gradient(
      to right,
      #00223e,
      #ffa17f
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
      to right,
      #00223e,
      #ffa17f
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    position: relative;

    & .profileIcon {
      width: 160px;
      height: 160px;
      border-radius: 50%;
      box-shadow: 0 3px 8px rgba(0, 0, 0, 0.4);
      background-color: #f5f5f5;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 200px;
      text-align: center;

      &:hover {
        & .uploadIcon {
          display: block;
        }
      }

      & .initials {
        color: #1b1c1e;
        font-size: 80px; /* 50% of parent */
        line-height: 1;
        position: relative;
        top: 40px; /* 25% of parent */
      }

      & .uploadIcon {
        position: absolute;
        bottom: 5px;
        left: 50%;
        transform: translateX(-50%);
        display: none;
      }

      &::before {
        content: "";
        border: 3px solid #1b1c1e;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        border-radius: 50%;
      }
    }
  }

  & .userName {
    margin-top: 5rem;
    padding-bottom: 1rem;
    text-align: center;
  }

  & .description {
    width: 75%;
    text-align: center;
    margin: auto;

    &:hover {
      & .edit {
        display: block;
      }
    }

    & .edit {
      position: absolute;
      bottom: 0px;
      right: 10px;
      display: none;
    }
  }
}
